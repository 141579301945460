import last from 'lodash/last';
import { useRouter } from 'next/router';
import qs from 'qs';
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  getSABudapestResumeSettingsMap,
  isBudapestNewDesignActive,
  isDesignWithSocialLink,
  isDesignWithIconSocialLink,
  isBruneiNewDesign,
  getBruneiHeaderTitleLines,
  getBruneiHeaderNameLines,
  isAtsNewDesign,
} from 'imports/generator/api/helpers';
import nookies from 'nookies';
import { useAccount } from '/imports/core/api/accounts/accountContext';
import ResponsiveContext from '/imports/core/api/responsiveContext';
import useIntl from '/imports/core/api/useIntl';
import useDomain from '/imports/core/hooks/useDomain';
import useTracking from '/imports/core/hooks/useTracking';
import Flex from '/imports/core/ui/atoms/Flex';
import DocxModel from '/imports/generator/ui/components/DocxModel';
import DropdownInner from '/imports/job-tracking/ui/atoms/DropdownInner';
import DropdownMenuItems from '/imports/job-tracking/ui/atoms/DropdownMenuItems';
import DropdownMenuItemWrapper from '/imports/job-tracking/ui/atoms/DropdownMenuItemWrapper';
import DropdownOption from '/imports/job-tracking/ui/atoms/DropdownOption';
import { cookieParser, exportDocx, getRoute, isBlogDomain } from '/lib/helpers';

// Map of Docx templates that are enabled for an environment.
const DocxTemplateEnabledMap = {
  budapest: true,
  'budapest-v2': true,
  'budapest-v3': true,
  'budapest-v4': true,
  riga: true,
  chicago: true,
  perth: true,
  shanghai: true,
  vladivostok: true,
  prague: true,
  sydney: true,
  montecarlo: true,
  kiev: true,
  sf: true,
  rotterdam: true,
};

const DownloadOptionsDropdown = (props) => {
  const { asPath } = useRouter();

  const { t, locale } = useIntl();
  const { trackEvent } = useTracking();
  const { docxEndpoint } = useDomain();
  const { currentUser } = useAccount();
  const { host } = useContext(ResponsiveContext);
  const [documentHeight, setDocumentHeight] = useState(null);
  const [modal, setModal] = useState(false);
  const [docxButtonLoading, setDocxButtonLoading] = useState(false);
  const { isCoverLetter, source: { settings = {} } = {}, source, show = false } = props;
  const { template, language, color, headingFont, contentFont, templateVersion } = settings;
  const { agent_client_id } = nookies.get({});

  // Checks if the current document allows Docx export.
  const allowsDocxExport = () => {
    // TODO: refactor this logic as the Docx list grows
    const isEnabled = DocxTemplateEnabledMap[template];
    if (!isCoverLetter && isEnabled) {
      return true;
    }
    return false;
  };

  const toggleModal = (e) => {
    e.stopPropagation();
    setModal(!modal);
  };

  useEffect(() => {
    const templateNode = document.getElementById('template-page');
    if (!templateNode) return;
    const { offsetHeight } = templateNode;
    setDocumentHeight(offsetHeight);
  }, [props.source]);

  const onClick = (resumeFormat) => () => {
    if (!isCoverLetter) {
      return trackEvent('download_cta', {
        context: last(asPath.split('/')),
        template: `${template}${templateVersion > 1 ? `-v${templateVersion}` : ''}`,
        cv_format: resumeFormat,
        resume_language: language,
        color,
        resume_headingFont: headingFont,
        resume_contentFont: contentFont,
      });
    }

    return trackEvent('cover_letter_download_cta', {
      template,
      format: resumeFormat,
    });
  };

  const onClickDownload = async (e) => {
    e.stopPropagation();
    if (docxButtonLoading) return;
    setDocxButtonLoading(true);
    const { token, agent_client_id } = cookieParser();
    const blob = await exportDocx({ token, link: getDocxExportLink(), userId: agent_client_id });
    const newBlob = new Blob([blob]);
    const downloadURL = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = 'resume.docx';
    link.click();
    setModal(!modal);
    onClick('docx')();
    setDocxButtonLoading(false);
  };

  const getDocxExportLink = () => {
    return `${docxEndpoint}/resumes/${source.id}`;
  };
  const isBrunei = template === 'brunei';
  const isAts = template === 'atsresume';
  const showIsBruneiHeaderLines = isBrunei && !!source?.details?.userPic;
  const queryString = {
    type: 'pdf',
    isDesignWithSocialLink: isDesignWithSocialLink(currentUser, agent_client_id),
    isDesignWithIconSocialLink: isDesignWithIconSocialLink(currentUser, agent_client_id),
    isBudapestDesignActive: isBudapestNewDesignActive(currentUser, agent_client_id),
    ...getSABudapestResumeSettingsMap(source, currentUser, agent_client_id),
    userId: currentUser && currentUser?.id,
    isBruneiNewDesign: isBruneiNewDesign(currentUser, agent_client_id, isBrunei),
    isAtsNewDesign: isAtsNewDesign(currentUser, agent_client_id, isAts),
    ...(showIsBruneiHeaderLines && {
      bruneiHeaderTitleLines: getBruneiHeaderTitleLines(source),
      bruneiHeaderNameLines: getBruneiHeaderNameLines(source),
    }),
  };

  if (documentHeight) {
    queryString.documentHeight = documentHeight;
  }
  queryString.locale = language || locale;
  queryString.redneg = currentUser?.gender || 'male';
  const path = isCoverLetter ? 'cover-letter' : 'resume';
  const url = `${origin}${getRoute(`/export/${path}/${source.id}?`, locale, false, host, isBlogDomain(host))}`;
  const pdfHref = `${url}${qs.stringify(queryString)}`;
  queryString.onePage = true;
  const onePagePdfHref = `${url}${qs.stringify(queryString)}`;

  return (
    <Wrapper show={show}>
      <Dropdown>
        <StyledDropdownMenuItems>
          <DropdownInner>
            <StyledLink href={onePagePdfHref} target="_blank" onClick={onClick('pdf')} passHref>
              <DropdownOption>{t('dashboard.export_1_page_pdf')}</DropdownOption>
            </StyledLink>
            <StyledLink href={pdfHref} target="_blank" onClick={onClick('pdf/A4')} passHref>
              <DropdownOption>{t('dashboard.export_a4_pdf')}</DropdownOption>
            </StyledLink>
            {allowsDocxExport() && <DropdownOption onClick={toggleModal}>{t('header.download_docx')}</DropdownOption>}
          </DropdownInner>
        </StyledDropdownMenuItems>
      </Dropdown>
      <DocxModel
        toggleModal={toggleModal}
        onClickDownload={onClickDownload}
        open={modal}
        link={getDocxExportLink()}
        loading={docxButtonLoading}
      />
    </Wrapper>
  );
};

export default DownloadOptionsDropdown;

const StyledDropdownMenuItems = styled(DropdownMenuItems)`
  display: none;
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const Dropdown = styled(DropdownMenuItemWrapper)`
  top: 40px;
  right: -55px;
`;

const Wrapper = styled(Flex)`
  ${({ show, theme }) =>
    show &&
    css`
      ${Dropdown} {
        padding-top: ${theme.isJobTrack ? '0px' : '4px'};
      }
      ${StyledDropdownMenuItems} {
        display: flex;
      }
    `}
`;
